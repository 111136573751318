<template>
  <DataTable v-if="created" ref="dataTable" :options="options">
    <template slot="filter">
      <v-col cols="12" sm="3">
        <vx-btn block outlined color="primary" @click="setStatus()">Paid for or later</vx-btn>
      </v-col>
      <v-col cols="12" sm="3">
        <v-switch
          v-model="options.filter.showCalenderImportedEvents"
          value="true"
          label="Show non marketplace events"
          @change="getCalenderImportedEvent"
        />
      </v-col>
      <!-- <v-col cols="12" sm="3">
        <vx-btn block outlined color="primary" @click="setExternalBooking()">External Events</vx-btn>
      </v-col> -->
    </template>
  </DataTable>
</template>

<script>
import _ from 'lodash';
import {
  BOOKING_STATUS,
  BOOKING_DETAILS,
  VENUE_TYPES,
  EVENT_TYPES,
  BOOKING_SOURCES,
  BOOKING_SOURCE,
} from '@tutti/constants';

export default {
  data() {
    return {
      created: false,
      options: {
        action: {
          hideEdit: true,
          child: 'review',
          showChildList: true,
        },
        export: true,
        filter: { showCalenderImportedEvents: false },
        filters: [],
        headers: [
          {
            text: 'Booking number',
            value: 'bookingNumber',
            filter: true,
            sortable: false,
            component: { name: 'dtView', props: { name: 'booking', permission: 'booking' } },
          },
          {
            text: 'Listing',
            value: 'venueData',
            filter: true,
            sortable: false,
            component: {
              name: 'dtView',
              props: {
                getter: booking => {
                  return booking.venueType === 'space'
                    ? this.$toView('space', booking.venue._id)
                    : this.$toView('location', booking.venue._id);
                },
                permission: booking => {
                  return booking.venueType === 'space' ? 'space' : 'location';
                },
              },
            },
          },
          {
            text: 'Status',
            value: 'status',
            sortable: false,
            component: 'dtBookingStatus',
          },
          {
            text: 'Start date',
            value: 'startDate',
            width: '150px',
            component: 'dtDate',
          },
          {
            text: 'Cost',
            value: 'cost',
            width: '100px',
            align: 'center',
            component: 'dtPrice',
          },
          {
            text: 'Artist',
            value: 'artistAccount',
            filter: true,
            sortable: false,
            component: { name: 'dtView', props: { name: 'account', permission: 'account' } },
          },
          {
            text: 'Host',
            value: 'hostAccount',
            filter: true,
            sortable: false,
            component: { name: 'dtView', props: { name: 'account', permission: 'account' } },
          },
        ],
      },
    };
  },

  created() {
    let status = _.reduce(
      BOOKING_DETAILS,
      (array, value, key) => {
        if (
          ![
            BOOKING_STATUS.CANCELLED_BY_ARTIST,
            BOOKING_STATUS.CANCELLED_BY_ADMIN,
            BOOKING_STATUS.CANCELLED_BY_HOST,
          ].includes(key)
        ) {
          array.push({
            text: value.status.admin,
            value: key,
          });
        }
        return array;
      },
      []
    );
    status = _.sortBy(status, 'text');
    status = _.uniqBy(status, 'text');

    this.options.filters = [
      {
        label: 'Booking status',
        items: status,
        key: 'status',
      },
      {
        label: 'Venue types',
        items: VENUE_TYPES,
        key: 'venueTypes',
      },
      {
        label: 'Enquiry source',
        items: BOOKING_SOURCES,
        key: 'source',
        onChange: () => {
          this.setFilterValue();
        },
      },
      {
        label: 'Event types',
        items: EVENT_TYPES,
        key: 'type',
      },
    ];

    this.created = true;
    this.options.filter.source = [BOOKING_SOURCE.TUTTI_MARKETPLACE];
  },

  methods: {
    setStatus() {
      this.options.filter.status = [
        BOOKING_STATUS.CLOSED,
        BOOKING_STATUS.COMPLETED,
        BOOKING_STATUS.TO_BE_COMPLETED,
        BOOKING_STATUS.BOOKED,
      ];

      this.$refs.dataTable.getData();
    },
    setExternalBooking() {
      this.options.filter.status = [BOOKING_STATUS.EXTERNAL];
      this.$refs.dataTable.getData();
    },
    setFilterValue() {
      let calenderImported = this.options.filter.source.includes(BOOKING_SOURCE.CALENDAR_IMPORT);
      this.options.filter.showCalenderImportedEvents = calenderImported ? 'true' : 'false';
      this.$refs.dataTable.getData();
    },

    getCalenderImportedEvent() {
      if (this.options.filter.showCalenderImportedEvents) {
        let sourceList = this.options.filter.source?.length ? this.options.filter.source : [];
        sourceList.push(BOOKING_SOURCE.CALENDAR_IMPORT);
        this.options.filter.source = sourceList;
      } else {
        var index = this.options.filter.source.indexOf(BOOKING_SOURCE.CALENDAR_IMPORT);
        if (index !== -1) {
          this.options.filter.source.splice(index, 1);
        }
      }
      this.$refs.dataTable.getData();
    },
  },
};
</script>
