var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.created ? _c('DataTable', {
    ref: "dataTable",
    attrs: {
      "options": _vm.options
    }
  }, [_c('template', {
    slot: "filter"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.setStatus();
      }
    }
  }, [_vm._v("Paid for or later")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-switch', {
    attrs: {
      "value": "true",
      "label": "Show non marketplace events"
    },
    on: {
      "change": _vm.getCalenderImportedEvent
    },
    model: {
      value: _vm.options.filter.showCalenderImportedEvents,
      callback: function callback($$v) {
        _vm.$set(_vm.options.filter, "showCalenderImportedEvents", $$v);
      },
      expression: "options.filter.showCalenderImportedEvents"
    }
  })], 1)], 1)], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }